<template>
    <div>
        <div class="title">小程序打卡地址</div>

        <el-divider></el-divider>

        <el-form
            ref="form"
            :rules="rules"
            label-position="top"
            :model="form"
            label-width="80px"
        >
            <el-row>
                <el-col :span="16">
                    <el-form-item label="打卡半径" prop="address">
                        <el-select
                            v-model="form.address"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入关键词"
                            :remote-method="remoteMethod"
                            :loading="searchLoading"
                            @change="onSelectorChange"
                        >
                            <el-option
                                v-for="item in searchOptions"
                                :key="item.id"
                                :label="item.address + item.title"
                                :value="item.address + item.title"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <div id="map" style="width: 1000px; height: 600px;"></div>
                </el-col>
                <el-col :span="6" style="display: flex;align-items: flex-end;">
                    <el-form-item label="打卡半径" prop="punch_range">
                        <el-input
                            v-model="form.punch_range"
                            type="number"
                            @change="onPunchRangeChange"
                        ></el-input
                        >米
                    </el-form-item>

                    <el-form-item class="form_btns" style="margin-left: 20px;">
                        <div class="form_btns">
                            <div
                                class="custom_button plain no_select"
                                @click="onSubmit"
                            >
                                保存
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";

/* eslint-disable no-undef */
export default {
    data() {
        return {
            map: null,
            searchLoading: false,
            searchOptions: [],
            value: "",
            form: {
                punch_range: 100,
                address: "",
                lat: "",
                lng: "",
            },
            rules: {
                address: [
                    {
                        required: true,
                        message: "请选择地址",
                        trigger: "change",
                    },
                ],
                punch_range: [
                    {
                        required: true,
                        message: "请填写打卡半径",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: ["id"],
    created() {
        if (this.id) {
            this.form.id = this.id;
            let datas = this.$route.query;
            console.log("query", datas);
            this.form.punch_range = datas.punch_range;
            this.form.address = datas.address;
            this.form.lat = datas.lat;
            this.form.lng = datas.lng;
        }
    },
    computed: {
        map_key() {
            return this.$store.state.map_key;
        },
    },
    mounted() {
        this.loadScript();
    },
    methods: {
        ...mapActions(["getPosition", "getSuggestion", "getGeocoder"]),
        ...mapActions("attendance", ["postMiniProgramPunchWay"]),

        initMap() {
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(document.getElementById("map"), {
                center: this.center, //设置地图中心点坐标
                zoom: 17.2, //设置地图缩放级别
                pitch: 43.5, //设置俯仰角
                rotation: 45, //设置地图旋转角度
                viewMode: "2D",
            });

            //初始化marker图层
            this.markerLayer = new TMap.MultiMarker({
                id: "marker-layer",
                map: this.map,
            });

            // 范围
            this.circle = new TMap.MultiCircle({
                map: this.map,
                styles: {
                    // 设置圆形样式
                    circle: new TMap.CircleStyle({
                        color: "rgba(41,91,255,0.16)",
                        showBorder: true,
                        borderColor: "rgba(41,91,255,1)",
                        borderWidth: 2,
                    }),
                },
            });

            // 点击选点
            this.map.on("click", (evt) => {
                console.log(evt);
                let { lat, lng } = evt.latLng;
                this.form.lat = lat;
                this.form.lng = lng;
                this.center = new TMap.LatLng(lat, lng);
                this.markerLayer.updateGeometries([
                    {
                        id: "center",
                        position: this.center,
                    },
                ]);
                this.circle.updateGeometries([
                    {
                        id: "range",
                        styleId: "circle",
                        center: this.center,
                        radius: Number(this.form.punch_range),
                    },
                ]);

                // 逆地址解析
                this.getGeocoder({
                    location: [lat, lng].join(","),
                })
                    .then((res) => {
                        console.log(res);
                        this.form.address = res.result.address;
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            });

            // 编辑初始化
            if (this.id) {
                this.map.setCenter(this.center);
                this.markerLayer.updateGeometries([
                    {
                        id: "center",
                        position: this.center,
                    },
                ]);
                this.circle.updateGeometries([
                    {
                        id: "range",
                        styleId: "circle",
                        center: this.center,
                        radius: Number(this.form.punch_range),
                    },
                ]);
            }
        },

        loadScript() {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src =
                "https://map.qq.com/api/gljs?v=1.exp&key=" + this.map_key;
            script.onload = script.onreadystatechange = async () => {
                if (
                    !this.readyState ||
                    this.readyState === "loaded" ||
                    this.readyState === "complete"
                ) {
                    // 自动定位
                    let res = await this.getPosition();
                    console.log(res);
                    this.location = res.result;
                    if (!this.id) {
                        // 没输入自动定位到中心
                        let { lat, lng } = this.location.location;
                        this.center = new TMap.LatLng(lat, lng);
                    } else {
                        // 否则定位到当前城市
                        this.center = new TMap.LatLng(
                            this.form.lat,
                            this.form.lng
                        );
                    }
                    this.initMap();
                    script.onload = script.onreadystatechange = null;
                }
            };
            document.body.appendChild(script);
        },

        remoteMethod(keyword) {
            if (keyword !== "") {
                this.loading = true;
                let { city: region } = this.location.ad_info;
                this.getSuggestion({ region, keyword })
                    .then((res) => {
                        console.log(res);
                        this.searchOptions = res.data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.loading = false;
                    });
            }
        },

        onSelectorChange(e) {
            console.log(e);
            let res = this.searchOptions.find(
                (item) => item.address + item.title === e
            );
            if (res) {
                let { lat, lng } = res.location;
                this.form.lat = lat;
                this.form.lng = lng;
                this.center = new TMap.LatLng(lat, lng);
                this.map.setCenter(this.center);
                this.markerLayer.updateGeometries([
                    {
                        id: "center",
                        position: this.center,
                    },
                ]);
                this.circle.updateGeometries([
                    {
                        id: "range",
                        styleId: "circle",
                        center: this.center,
                        radius: Number(this.form.punch_range),
                    },
                ]);
            }
        },

        onPunchRangeChange(e) {
            let range = Number(e);
            if (range > 0) {
                this.form.punch_range = range;
                if (this.circle.geometries.length > 0) {
                    this.circle.updateGeometries([
                        {
                            id: "range",
                            styleId: "circle",
                            center: this.center,
                            radius: Number(this.form.punch_range),
                        },
                    ]);
                }
            } else {
                this.form.punch_range = "";
            }
        },

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postMiniProgramPunchWay(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                            this.$router.back();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.el-select {
    width: 1000px;
}

::v-deep .el-form-item__content {
    display: flex;

    .el-input {
        margin-right: 10px;
    }
}
</style>
